import get from "lodash/fp/get";

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';

export const useDeleteToken = () => {
  const { deleteToken, listTokensByUserId } = useActions(actions);
  return async values => {
    try {
      const fetchToken = await listTokensByUserId({ 
        tokenUserId: values.userId,
        filter: { service: { eq: values.service }}
      });
      const tokenId = get('value.items[0].id', fetchToken);
      const deletedToken = await deleteToken({
        input: { id: tokenId }
      });
      return deletedToken;
    } catch (e) {
      console.log(e);
      return e;
    }
  }
};