import { Storage, API, graphqlOperation} from "aws-amplify";
import {v4 as uuid } from 'uuid';

import awsmobile from '../../src/aws-exports';
import { createAsset, updateAsset, deleteAsset } from '../../src';

export const _createAsset = async values => {
  const { data } = await API.graphql(graphqlOperation(createAsset, values));
  return data;
};

export const _updateAsset = async values => {
  const { data } = await API.graphql(graphqlOperation(updateAsset, values));
  return data;
};

export const _deleteAsset = async values => {
  const { data } = await API.graphql(graphqlOperation(deleteAsset, values));
  return data;
};

/* 
	UPLOAD IMAGE ON AMAZON S3 AND RETURN URL
*/
const {
	aws_user_files_s3_bucket_region: region,
	aws_user_files_s3_bucket: bucket
} = awsmobile;

export const createS3Asset = async (file, folderName) => {
	const fileName = file.name.replaceAll(' ', '_');
	const { type } = file;
	const key = `${folderName}/${uuid()}-${fileName}`;
  const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`;
	try {
		const res = await Storage.put(key, file, {
			contentType: type
    })
    return url;
	} catch (err) {
		console.log('error', err);
	}
};