// const TIME_TABLE = [
//   '12:00 AM',
//   '12:15 AM',
//   '12:30 AM',
//   '12:45 AM',
//   '1:00 AM',
//   '1:15 AM',
//   '1:30 AM',
//   '1:45 AM',
//   '2:00 AM',
//   '2:15 AM',
//   '2:30 AM',
//   '2:45 AM',
//   '3:00 AM',
//   '3:15 AM',
//   '3:30 AM',
//   '3:45 AM',
//   '4:00 AM',
//   '4:15 AM',
//   '4:30 AM',
//   '4:45 AM',
//   '5:00 AM',
//   '5:15 AM',
//   '5:30 AM',
//   '5:45 AM',
//   '6:00 AM',
//   '6:15 AM',
//   '6:30 AM',
//   '6:45 AM',
//   '7:00 AM',
//   '7:15 AM',
//   '7:30 AM',
//   '7:45 AM',
//   '8:00 AM',
//   '8:15 AM',
//   '8:30 AM',
//   '8:45 AM',
//   '9:00 AM',
//   '9:15 AM',
//   '9:30 AM',
//   '9:45 AM',
//   '10:00 AM',
//   '10:15 AM',
//   '10:30 AM',
//   '10:45 AM',
//   '11:00 AM',
//   '11:15 AM',
//   '11:30 AM',
//   '11:45 AM',
//   '12:00 PM',
//   '12:15 PM',
//   '12:30 PM',
//   '12:45 PM',
//   '1:00 PM',
//   '1:15 PM',
//   '1:30 PM',
//   '1:45 PM',
//   '2:00 PM',
//   '2:15 PM',
//   '2:30 PM',
//   '2:45 PM',
//   '3:00 PM',
//   '3:15 PM',
//   '3:30 PM',
//   '3:45 PM',
//   '4:00 PM',
//   '4:15 PM',
//   '4:30 PM',
//   '4:45 PM',
//   '5:00 PM',
//   '5:15 PM',
//   '5:30 PM',
//   '5:45 PM',
//   '6:00 PM',
//   '6:15 PM',
//   '6:30 PM',
//   '6:45 PM',
//   '7:00 PM',
//   '7:15 PM',
//   '7:30 PM',
//   '7:45 PM',
//   '8:00 PM',
//   '8:15 PM',
//   '8:30 PM',
//   '8:45 PM',
//   '9:00 PM',
//   '9:15 PM',
//   '9:30 PM',
//   '9:45 PM',
//   '10:00 PM',
//   '10:15 PM',
//   '10:30 PM',
//   '10:45 PM',
//   '11:00 PM',
//   '11:15 PM',
//   '11:30 PM',
//   '11:45 PM',
// ];

export const TIME_TABLE = [
  { twentyFourHour: '00:00', twelveHour: '12:00 AM' },
  { twentyFourHour: '00:15', twelveHour: '12:15 AM' },
  { twentyFourHour: '00:30', twelveHour: '12:30 AM' },
  { twentyFourHour: '00:45', twelveHour: '12:45 AM' },
  { twentyFourHour: '01:00', twelveHour: '1:00 AM' },
  { twentyFourHour: '01:15', twelveHour: '1:15 AM' },
  { twentyFourHour: '01:30', twelveHour: '1:30 AM' },
  { twentyFourHour: '01:45', twelveHour: '1:45 AM' },
  { twentyFourHour: '02:00', twelveHour: '2:00 AM' },
  { twentyFourHour: '02:15', twelveHour: '2:15 AM' },
  { twentyFourHour: '02:30', twelveHour: '2:30 AM' },
  { twentyFourHour: '02:45', twelveHour: '2:45 AM' },
  { twentyFourHour: '03:00', twelveHour: '3:00 AM' },
  { twentyFourHour: '03:15', twelveHour: '3:15 AM' },
  { twentyFourHour: '03:30', twelveHour: '3:30 AM' },
  { twentyFourHour: '03:45', twelveHour: '3:45 AM' },
  { twentyFourHour: '04:00', twelveHour: '4:00 AM' },
  { twentyFourHour: '04:15', twelveHour: '4:15 AM' },
  { twentyFourHour: '04:30', twelveHour: '4:30 AM' },
  { twentyFourHour: '04:45', twelveHour: '4:45 AM' },
  { twentyFourHour: '05:00', twelveHour: '5:00 AM' },
  { twentyFourHour: '05:15', twelveHour: '5:15 AM' },
  { twentyFourHour: '05:30', twelveHour: '5:30 AM' },
  { twentyFourHour: '05:45', twelveHour: '5:45 AM' },
  { twentyFourHour: '06:00', twelveHour: '6:00 AM' },
  { twentyFourHour: '06:15', twelveHour: '6:15 AM' },
  { twentyFourHour: '06:30', twelveHour: '6:30 AM' },
  { twentyFourHour: '06:45', twelveHour: '6:45 AM' },
  { twentyFourHour: '07:00', twelveHour: '7:00 AM' },
  { twentyFourHour: '07:15', twelveHour: '7:15 AM' },
  { twentyFourHour: '07:30', twelveHour: '7:30 AM' },
  { twentyFourHour: '07:45', twelveHour: '7:45 AM' },
  { twentyFourHour: '08:00', twelveHour: '8:00 AM' },
  { twentyFourHour: '08:15', twelveHour: '8:15 AM' },
  { twentyFourHour: '08:30', twelveHour: '8:30 AM' },
  { twentyFourHour: '08:45', twelveHour: '8:45 AM' },
  { twentyFourHour: '09:00', twelveHour: '9:00 AM' },
  { twentyFourHour: '09:15', twelveHour: '9:15 AM' },
  { twentyFourHour: '09:30', twelveHour: '9:30 AM' },
  { twentyFourHour: '09:45', twelveHour: '9:45 AM' },
  { twentyFourHour: '10:00', twelveHour: '10:00 AM' },
  { twentyFourHour: '10:15', twelveHour: '10:15 AM' },
  { twentyFourHour: '10:30', twelveHour: '10:30 AM' },
  { twentyFourHour: '10:45', twelveHour: '10:45 AM' },
  { twentyFourHour: '11:00', twelveHour: '11:00 AM' },
  { twentyFourHour: '11:15', twelveHour: '11:15 AM' },
  { twentyFourHour: '11:30', twelveHour: '11:30 AM' },
  { twentyFourHour: '11:45', twelveHour: '11:45 AM' },
  { twentyFourHour: '12:00', twelveHour: '12:00 PM' },
  { twentyFourHour: '12:15', twelveHour: '12:15 PM' },
  { twentyFourHour: '12:30', twelveHour: '12:30 PM' },
  { twentyFourHour: '12:45', twelveHour: '12:45 PM' },
  { twentyFourHour: '13:00', twelveHour: '1:00 PM' },
  { twentyFourHour: '13:15', twelveHour: '1:15 PM' },
  { twentyFourHour: '13:30', twelveHour: '1:30 PM' },
  { twentyFourHour: '13:45', twelveHour: '1:45 PM' },
  { twentyFourHour: '14:00', twelveHour: '2:00 PM' },
  { twentyFourHour: '14:15', twelveHour: '2:15 PM' },
  { twentyFourHour: '14:30', twelveHour: '2:30 PM' },
  { twentyFourHour: '14:45', twelveHour: '2:45 PM' },
  { twentyFourHour: '15:00', twelveHour: '3:00 PM' },
  { twentyFourHour: '15:15', twelveHour: '3:15 PM' },
  { twentyFourHour: '15:30', twelveHour: '3:30 PM' },
  { twentyFourHour: '15:45', twelveHour: '3:45 PM' },
  { twentyFourHour: '16:00', twelveHour: '4:00 PM' },
  { twentyFourHour: '16:15', twelveHour: '4:15 PM' },
  { twentyFourHour: '16:30', twelveHour: '4:30 PM' },
  { twentyFourHour: '16:45', twelveHour: '4:45 PM' },
  { twentyFourHour: '17:00', twelveHour: '5:00 PM' },
  { twentyFourHour: '17:15', twelveHour: '5:15 PM' },
  { twentyFourHour: '17:30', twelveHour: '5:30 PM' },
  { twentyFourHour: '17:45', twelveHour: '5:45 PM' },
  { twentyFourHour: '18:00', twelveHour: '6:00 PM' },
  { twentyFourHour: '18:15', twelveHour: '6:15 PM' },
  { twentyFourHour: '18:30', twelveHour: '6:30 PM' },
  { twentyFourHour: '18:45', twelveHour: '6:45 PM' },
  { twentyFourHour: '19:00', twelveHour: '7:00 PM' },
  { twentyFourHour: '19:15', twelveHour: '7:15 PM' },
  { twentyFourHour: '19:30', twelveHour: '7:30 PM' },
  { twentyFourHour: '19:45', twelveHour: '7:45 PM' },
  { twentyFourHour: '20:00', twelveHour: '8:00 PM' },
  { twentyFourHour: '20:15', twelveHour: '8:15 PM' },
  { twentyFourHour: '20:30', twelveHour: '8:30 PM' },
  { twentyFourHour: '20:45', twelveHour: '8:45 PM' },
  { twentyFourHour: '21:00', twelveHour: '9:00 PM' },
  { twentyFourHour: '21:15', twelveHour: '9:15 PM' },
  { twentyFourHour: '21:30', twelveHour: '9:30 PM' },
  { twentyFourHour: '21:45', twelveHour: '9:45 PM' },
  { twentyFourHour: '22:00', twelveHour: '10:00 PM' },
  { twentyFourHour: '22:15', twelveHour: '10:15 PM' },
  { twentyFourHour: '22:30', twelveHour: '10:30 PM' },
  { twentyFourHour: '22:45', twelveHour: '10:45 PM' },
  { twentyFourHour: '23:00', twelveHour: '11:00 PM' },
  { twentyFourHour: '23:15', twelveHour: '11:15 PM' },
  { twentyFourHour: '23:30', twelveHour: '11:30 PM' },
  { twentyFourHour: '23:45', twelveHour: '11:45 PM' },
  { twentyFourHour: '23:59', twelveHour: '11:59 PM' }
];