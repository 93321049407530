import { 
	_createAsset, 
	_updateAsset,
	_deleteAsset,
	createS3Asset, 
} from '../../api/aws/Asset';

export const useCreateAsset = () => {
	return async ({ file, s3FolderName, values }) => {
    try {
      const url = await createS3Asset(file, s3FolderName);
      const data = await _createAsset({ input: {
      	...values,
      	url
      }});
      return data;
    } catch(e) {
      console.log(e);
      return e;
    }
	}
};

export const useUpdateAsset = () => {
	return async (values) => {
		const data = await _updateAsset({ input: values });
		return data;
	}
};

export const useDeleteAsset = () => {
	return async (url, values) => {
		const data = await _deleteAsset({ input: values });
		const removeFromS3 = await deleteS3Asset(url);
		return data;
	}
};