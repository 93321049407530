import { API, graphqlOperation} from "aws-amplify";
import get from 'lodash/fp/get';
import { Auth } from "aws-amplify";

import { getConfig } from './use-mailchimp';
import { 
    onCreateGroupContact, 
    onDeleteGroupContact, 
    onCreateGroupCompany, 
    onDeleteGroupCompany, 
    onCreateCompany,
    onDeleteCompany, 
    onCreateContact, 
    onDeleteContact,
    updateGroupCompany,
    updateGroupContact,
    onUpdateContact,
    onUpdateCompany,
    onCreateEvent,
    onUpdateEvent,
    onDeleteEvent,
    onDeleteGroupContactSlim,
    onDeleteGroupCompanySlim
} from "../../src";

export const useForceMailchimpUpdate = () => {
    return async data => {
        const { contact, group } = data;
        try {
            const config = await getConfig();
            const values = {
                email_address: contact.primary_email,
                status_if_new: 'subscribed',
                status: 'subscribed',
                merge_fields: {
                    FNAME: contact.first_name,
                    LNAME: contact.last_name ? contact.last_name : "",
                    EMAIL: contact.primary_email,
                }
            };
            if(group.mailchimp_list_id) {
                // Update Mailchimp member
                const init = {
                    body: {
                        config,
                        list_id: group.mailchimp_list_id,
                        subscriber_hash: contact.mailchimp_id,
                        values
                    }
                };
                const updatedMember = await API.post('mailchimpEndpointsAPI', '/updateMember', init);
                return updatedMember;
            }
        }catch(e){
            console.log(e);
            return e;
        }
    }
}

export const useOnCreateGroupContact = () => API.graphql(graphqlOperation(onCreateGroupContact))
.subscribe({ next: async data => {
    console.log(data)
    const { onCreateGroupContact: groupContact } = data.value.data;
    const { contact, group } = groupContact;
    if (!contact.primary_email){
        console.log('Contact has no email. Can\'t update mailchimp')
        return;
    }
    try {
        const config = await getConfig();
        const values = {
            email_address: contact.primary_email,
            status_if_new: 'subscribed',
            status: 'subscribed',
            merge_fields: {
                FNAME: contact.first_name,
                LNAME: contact.last_name ? contact.last_name : "",
                EMAIL: contact.primary_email,
            }
        };
        // Add or remove tags that are based on regions LA or SF
        if(group.type === 'region'){
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_newsletter_id,
                    subscriber_hash: contact.mailchimp_id,
                    tag_name: group.mailchimp_tag_name,
                    status: 'active'
                }
            }
            const updatedMemberTag = await API.post('mailchimpEndpointsAPI', '/updateMemberTags', init);
        }
        if(group.mailchimp_list_id) {
            // Update Mailchimp member
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_list_id,
                    subscriber_hash: contact.mailchimp_id,
                    values
                }
            };
            const updatedMember = await API.post('mailchimpEndpointsAPI', '/updateMember', init);
            console.log(updatedMember)

            // Update groupContact mailchimp_status
            const updatedGroupContact = await API.graphql(graphqlOperation(updateGroupContact, {
                input: {
                    id: groupContact.id,
                    mailchimp_status: 'subscribed'
                }
            }))
            console.log('updatedGroupContact',updatedGroupContact)
        }
    } catch(err) {
        console.log(err)
    }
}});

export const useOnDeleteGroupContact = () => API.graphql(graphqlOperation(onDeleteGroupContactSlim))
.subscribe({ next: async data => {
    console.log(data)
    const { onDeleteGroupContact: groupContact } = data.value.data;
    const { contact, group } = groupContact;
    if (!contact.primary_email){
        console.log('Contact has no email. Can\'t update mailchimp')
        return;
    }
    try {
        const config = await getConfig();
        const values = {
            email_address: contact.primary_email,
            status_if_new: 'unsubscribed',
            status: 'unsubscribed',
            merge_fields: {
                FNAME: contact.first_name,
                LNAME: contact.last_name ? contact.last_name : "",
                EMAIL: contact.primary_email,
            }
        };
        // Add or remove tags that are based on regions LA or SF
        if(group.type === 'region'){
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_newsletter_id,
                    subscriber_hash: contact.mailchimp_id,
                    tag_name: group.mailchimp_tag_name,
                    status: 'inactive'
                }
            }
            const updatedMemberTag = await API.post('mailchimpEndpointsAPI', '/updateMemberTags', init);
            console.log(updatedMemberTag);
        }
        if(group.mailchimp_list_id) {
            // Update Mailchimp member
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_list_id,
                    subscriber_hash: contact.mailchimp_id,
                    values
                }
            };
            const updatedMember = await API.post('mailchimpEndpointsAPI', '/updateMember', init);
            console.log(updatedMember)
        }
    } catch(err) {
        console.log(err)
    }
}});

export const useOnCreateGroupCompany = () => API.graphql(graphqlOperation(onCreateGroupCompany))
.subscribe({ next: async data => {
    console.log(data)
    const { onCreateGroupCompany: groupCompany } = data.value.data;
    const { company, group } = groupCompany;
    if (!company.email){
        console.log('Company has no email. Can\'t update mailchimp')
        return;
    }
    try {
        const config = await getConfig();
        const values = {
            email_address: company.email,
            status_if_new: 'subscribed',
            status: 'subscribed',
            merge_fields: {
                FNAME: company.name,
                // LNAME: "",
                EMAIL: company.email,
            }
        };
        // Add or remove tags that are based on regions LA or SF
        if(group.type === 'region'){
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_newsletter_id,
                    subscriber_hash: company.mailchimp_id,
                    tag_name: group.mailchimp_tag_name,
                    status: 'active'
                }
            }
            const updatedMemberTag = await API.post('mailchimpEndpointsAPI', '/updateMemberTags', init);
        }
        if(group.mailchimp_list_id) {
            // Update Mailchimp member
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_list_id,
                    subscriber_hash: company.mailchimp_id,
                    values
                }
            };
            const updatedMember = await API.post('mailchimpEndpointsAPI', '/updateMember', init);
            console.log('updatedMember',updatedMember)

            // Update groupCompany mailchimp_status
            const updatedGroupCompany = await API.graphql(graphqlOperation(updateGroupCompany, {
                input: {
                    id: groupCompany.id,
                    mailchimp_status: 'subscribed'
                }
            }))
            console.log('updatedGroupCompany',updatedGroupCompany)
        }
    } catch(err) {
        console.log(err)
    }
}});

export const useOnDeleteGroupCompany = () => API.graphql(graphqlOperation(onDeleteGroupCompanySlim))
.subscribe({ next: async data => {
    console.log(data)
    const { onDeleteGroupCompany: groupCompany } = data.value.data;
    const { company, group } = groupCompany;
    if (!company.email){
        console.log('Company has no email. Can\'t update mailchimp')
        return;
    }
    try {
        const config = await getConfig();
        const values = {
            email_address: company.email,
            status_if_new: 'unsubscribed',
            status: 'unsubscribed',
            merge_fields: {
                FNAME: company.name,
                // LNAME: "",
                EMAIL: company.email,
            }
        };
        // Add or remove tags that are based on regions LA or SF
        if(group.type === 'region'){
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_newsletter_id,
                    subscriber_hash: company.mailchimp_id,
                    tag_name: group.mailchimp_tag_name,
                    status: 'inactive'
                }
            }
            const updatedMemberTag = await API.post('mailchimpEndpointsAPI', '/updateMemberTags', init);
            console.log(updatedMemberTag);
        }
        if(group.mailchimp_list_id) {
            // Update Mailchimp member
            const init = {
                body: {
                    config,
                    list_id: group.mailchimp_list_id,
                    subscriber_hash: company.mailchimp_id,
                    values
                }
            };
            const updatedMember = await API.post('mailchimpEndpointsAPI', '/updateMember', init);
            console.log(updatedMember)
        }
    } catch(err) {
        console.log(err)
    }
}});

export const useOnCreateCompany = callback => {
    return API.graphql(graphqlOperation(onCreateCompany)).subscribe({
        next: callback,
        error: error => console.log(error)
    });
};

export const useOnDeleteCompany = callback => {
    return API.graphql(graphqlOperation(onDeleteCompany)).subscribe({
        next: callback,
        error: error => console.log(error)
    });
};

export const useOnCreateContact = callback => {
    return API.graphql(graphqlOperation(onCreateContact)).subscribe({
        next: callback,
        error: error => console.log(error)
    });
};

export const useOnDeleteContact = callback => {
    return API.graphql(graphqlOperation(onDeleteContact)).subscribe({
        next: callback,
        error: error => console.log(error)
    });
};

export const useOnUpdateContact = callback => {
    return API.graphql(graphqlOperation(onUpdateContact)).subscribe({
        next: callback,
        error: error => console.log(error)
    });
};

export const useOnUpdateCompany = callback => {
    return API.graphql(graphqlOperation(onUpdateCompany)).subscribe({
        next: callback,
        error: error => console.log(error)
    });
};

export const useOnCreateEvent = () => API.graphql(graphqlOperation(onCreateEvent))
.subscribe({ next: async data => {
    console.log(data)
    try {
        const userId = Auth.user.username;
        const event = data.value.data.onCreateEvent;
        const googleCalendarId = get('calendar.googleCalendarId', event);
        if (googleCalendarId && event.source === 'CONNECT'){
            const init = {
                body: {
                    typeSubscription: 'INSERT',
                    userId, 
                    googleCalendarId, 
                    event
                }
            };
            const updatedGoogleCalendar = await API.post('googleCalendarEndpointsAPI', '/subscriptions', init);
            console.log({updatedGoogleCalendar});
        }
    } catch (err) {
        console.log(err);
    }
}});

export const useOnUpdateEvent = () => API.graphql(graphqlOperation(onUpdateEvent))
.subscribe({ next: async data => {
    console.log(data)
    try {
        const userId = Auth.user.username;
        const event = data.value.data.onUpdateEvent;
        const googleCalendarId = get('calendar.googleCalendarId', event);
        if (googleCalendarId){
            const init = {
                body: {
                    typeSubscription: 'MODIFY',
                    userId,
                    googleCalendarId, 
                    event
                }
            };
            const updatedGoogleCalendar = await API.post('googleCalendarEndpointsAPI', '/subscriptions', init);
            console.log({updatedGoogleCalendar});
        }
    } catch (err) {
        console.log(err);
    }
}});

export const useOnDeleteEvent = () => API.graphql(graphqlOperation(onDeleteEvent))
.subscribe({ next: async data => {
    console.log(data)
    try {
        const userId = Auth.user.username;
        const event = data.value.data.onDeleteEvent;
        const googleCalendarId = get('calendar.googleCalendarId', event);
        if (googleCalendarId){
            const init = {
                body: {
                    typeSubscription: 'REMOVE',
                    userId,
                    googleCalendarId,
                    event
                }
            };
            const updatedGoogleCalendar = await API.post('googleCalendarEndpointsAPI', '/subscriptions', init);
            console.log({updatedGoogleCalendar});
        }
    } catch (err) {
        console.log(err);
    }
}});