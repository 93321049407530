import { 
  listTradeProgramForms,
  listTradeProgramFormsByCreatedAt,
} from "../../src";
import { API, graphqlOperation } from "aws-amplify";

export const useTradeProgramForms = async (input, sortDirection) => {
  // console.log(input)
  const args = {
    type: "TradeProgramForm",
    limit: 5000,
    sortDirection,
  };
  if (input) {
    args["filter"] = {}
  }
  if (input) {
    args["filter"]["or"] = [
        { 
          searchFirstName: {
            beginsWith: input.toLowerCase()
          }
        },
        {
          searchLastName: {
            beginsWith: input.toLowerCase()
          }
        },
        {
          email: {
            beginsWith: input.toLowerCase()
          }
        }
    ]
  }
  // console.log(args);
  const { data } = await API.graphql(graphqlOperation(listTradeProgramFormsByCreatedAt, args));
  return data;
};

export const useUnseenTradeProgramForms = async (timestamp) => {
  const { data } = await API.graphql(graphqlOperation(listTradeProgramForms, {
    filter: {
      createdAt: {
        gt: timestamp
      }
    }
  }));
  return data;
};
