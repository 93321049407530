import awsconfig from '../../src/aws-exports';
import { find } from 'lodash';

// API gateway for gmailAuthorize lambda function
const gmailAuthorizeAPI = find(
    awsconfig.aws_cloud_logic_custom, 
    ['name', 'gmailAuthorizeAPI']
);
// Each amplify env has a different Google client ID
const LIST_CLIENT_ID = {
    'production': '1020413683959-vb94vceej6hjvsnub8plppp3o316ntg7.apps.googleusercontent.com',
    'staging': '318793229421-9gpkkihacn57mu0o3b2cbomkqqt4pnp8.apps.googleusercontent.com',
    'testing': '907555200932-g22lopo5v06ual6mtkdbjvsg3mgcqdtn.apps.googleusercontent.com',
    'develine': '1061632345254-jul0rq5jbgkaab7kerlmdjr331hqiql6.apps.googleusercontent.com',
    'devalex': '406635433007-32ojhbpf27g9cnovmnbtrnlnd4rkh8er.apps.googleusercontent.com',
    'deva': '904781367515-0gjro4hp6b7tkhhcstt4hnpio6bmnfv5.apps.googleusercontent.com'
};
const currentEnv = gmailAuthorizeAPI.endpoint && gmailAuthorizeAPI.endpoint.split('amazonaws.com/')[1];
const CLIENT_ID = LIST_CLIENT_ID[currentEnv];
const SCOPES = 'https://www.googleapis.com/auth/gmail.send';
const REDIRECT_URI = currentEnv === 'production' ? 
  'https://api.connect.panafold.com/gmailAuthorize/auth' : 
  `${gmailAuthorizeAPI && gmailAuthorizeAPI.endpoint}/auth`;
const AUTH_URI = 'https://accounts.google.com/o/oauth2/v2/auth';
export const GMAIL_AUTH_URL = `${AUTH_URI}?access_type=offline&scope=${SCOPES}&response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&prompt=consent`;