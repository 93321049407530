import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import get from 'lodash/fp/get';
import { API } from "aws-amplify";
import lowerCase from 'lodash/fp/lowerCase';
import findIndex from 'lodash/findIndex';

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import { useDeleteEvent } from './use-event';

export const useCreateCalendar = () => {
	const { createCalendar, listCalendarsByUser, createPermission } = useActions(actions);
  const userId = Auth.user.username;
	return async (values) => {
		// console.log(values)
		try {			
			const primaryCalendar = await listCalendarsByUser({ calendarUserId: userId, filter: { primary: { eq: true }}})
        .then(res => res.value.items);
      // console.log({primaryCalendar})
      const createdCalendar = await createCalendar({
        input: {
          calendarUserId: userId, 
          name: values.name, 
          primary: primaryCalendar.length ? false : true, 
					color: values.color ? values.color : "#1976d2",
        }
      });
      console.log({createdCalendar})
			const calendarId = get('value.id', createdCalendar);
			// Add permission
			const createdPermission = await createPermission({
				input: {
					type: "Calendar",
					rule: 'OWNER',
					viewActive: true,
					permissionCalendarId: calendarId, 
					permissionUserId: userId
				}
			}) ;
			console.log({createdPermission})
      return createdCalendar;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
};

export const useUpdateCalendar = () => {
	const { updateCalendar } = useActions(actions);
	return async (values) => {
		// console.log(values)
		try {			
      const updatedCalendar = await updateCalendar({
        input: values
      });
      console.log({updatedCalendar})
      return updatedCalendar;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
};

export const useDeleteCalendar = () => {
	const { deleteCalendar, getCalendar, deletePermission } = useActions(actions);
	const deleteEvent = useDeleteEvent();
	return async (values) => {
		console.log(values)
		try {			
			const calendar = await getCalendar({ id: values.id });
			console.log({ calendar })
			const events = calendar.value.events.items;
			console.log({ events })
			const deletedEvents = await Promise.all(events.map(event => deleteEvent(event)))
			console.log({ deletedEvents });
      const deletedCalendar = await deleteCalendar({ input: { id: values.id }});
      console.log({ deletedCalendar })
			const permissions = calendar.value.permissions.items;
			console.log({permissions})
			const deletedPermissions = await Promise.all(permissions.map(permission => deletePermission({ input: { id: permission.id }})));
			console.log({deletedPermissions})
      return deletedCalendar;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
};

export const useCalendar = (id) => {
	const { getCalendar } = useActions(actions);
	const [calendar, setCalendar] = useState(null);
	const fetchCalendar = async () => {
		try {
      const data = await getCalendar({ id });
			setCalendar(data.value);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => { if (id) fetchCalendar() }, []);
	return [{ calendar }];
};

export const useCalendars = () => {
	const { listCalendars } = useActions(actions);
	const [calendars, setCalendars] = useState([]);
	const fetchCalendars = async () => {
		try {
      const data = await listCalendars();
			setCalendars(data.value.items);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => { fetchCalendars() }, []);
	return [{ calendars }];
};

export const useListCalendarsByUser = (trigger) => {
	const { getCalendarsByUser } = useActions(actions);
	const [calendars, setCalendars] = useState([]);
  const userId = Auth.user.username;
	const fetchCalendars = async () => {
		try {
      const data = await getCalendarsByUser({ calendarUserId: userId });
			setCalendars(data.value.listCalendarsByUser.items);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => { fetchCalendars() }, [trigger]);
	return [{ calendars }];
};

export const useCreateCalendarPermissions = () => {
	const { createPermission } = useActions(actions);
	return async (values) => {
		// console.log(values)
		try {			
      const createdPermissions = await Promise.all(values.map(permission => createPermission({
        input: { 
					permissionCalendarId: permission.calendarId, 
					permissionUserId: permission.userId, 
					viewActive: true,
					rule: permission.rule, 
					type: "Calendar"
				}
      })))
      console.log({createdPermissions})
      return createdPermissions;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
};

export const useUpdateCalendarPermissions = () => {
	const { updatePermission } = useActions(actions);
	return async (values) => {
		// console.log(values)
		try {			
      const updatedPermissions = await Promise.all(values.map(permission => updatePermission({
        input: {
					id: permission.id, 
					rule: get('rule.value', permission),
				}
      })))
      console.log({updatedPermissions})
      return updatedPermissions;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
};

export const useUpdateCalendarSettings = ({ userId, jwtToken }) => {
	const { updateCalendar, updatePermission, createPermission, deletePermission } = useActions(actions);
	return async (values) => {
		console.log(values)
		try {			
			if (values.primary && values.currentPrimaryId !== values.id) {
				// Edit previous primaryCalendar
				const updatedPrimaryCalendar = await updateCalendar({
					input: {
						id: values.currentPrimaryId,
						primary: false
					}
				});
				console.log({updatedPrimaryCalendar})
			}
			// Edit Calendar
			const updatedCurrentCalendar = await updateCalendar({
				input: {
					id: values.id,
					name: values.name,
					color: values.color,
					primary: values.primary
				}
			});
			console.log({updatedCurrentCalendar})
			/* 
				If calendar syncs Google Calendar:
				- Remove permissions if any from values.removePermissions list
				- Concat all permissions (current and new)
				- For each permission:
					- If permission has ACL:
						- If Connect level > Google level
							- Create ACL with Connect level
								Success: Continue
								Error: Notify
					- Else (permission without ACL):
						- Create ACL:
							Success: Continue
							Error: Notify
					- If permission.id => update permission; else => create permission

				If calendar not syncs with Google Calendar:
				- Remove permissions if any from values.removePermissions list
				- Concat all permissions (current and new)
				- If permission.id => update permission; else => create permission
			*/
			if (values.googleCalendarId){ // Connect Calendar syncs with Google
				const removedPermissions = await Promise.all(values.removePermissions.map(async permission => {
					return await deletePermission({ input: { id: permission.id }})
				} ));
				console.log({removedPermissions});
				const ruleLevels = [ // The index order matters. Higher index, higher level of restriction.
					{ google: 'none', connect: 'NONE' },
					{ google: 'freeBusyReader', connect: 'NONE' },
					{ google: 'reader', connect: 'READER' },
					{ google: 'writer', connect: 'WRITER' },
					{ google: 'owner', connect: 'OWNER' },
				];
				const acls = await API.get('googleCalendarEndpointsAPI', '/acls', { 
					queryStringParameters: { userId, jwtToken, googleCalendarId: values.googleCalendarId }
				}).then(res => res.data);
				console.log({acls})
				const newPermissions = values.newPermissions.map(permission => ({
					...permission,
					rule: { value: get('newPermissionsValue.value', values) }
				}))
				const permissions = values.currentPermissions
					.concat(newPermissions)
					.map(permission => ({
						...permission,
						acl: acls.find(item => item.scope.value === permission.user.email ) || null
					}));
				console.log({permissions})
				await Promise.all(permissions.map(async permission => {
					try {
						if (permission.acl){ // Permission with Google ACL
							const googleLevel = findIndex(ruleLevels, ['google', permission.acl.role]);
							const connectLevel = findIndex(ruleLevels, ['connect', permission.rule.value]);
							console.log({connectLevel,googleLevel})
							if (connectLevel > googleLevel){
								const updatedAcl = await API.put('googleCalendarEndpointsAPI', '/acl', {
									body: {
										userId, 
										jwtToken,
										googleCalendarId: values.googleCalendarId,
										role: lowerCase(get('rule.value', permission)),
										scope: {
											type: 'user',
											value: get('user.email', permission),
										}
									}
								});
								console.log({updatedAcl})
								if (updatedAcl.error){
									throw Error(updatedAcl.error);
								}
							}
						} else { // Permission without Google ACL
							const createdAcl = await API.put('googleCalendarEndpointsAPI', '/acl', {
								body: {
									userId, 
									jwtToken,
									googleCalendarId: values.googleCalendarId,
									role: lowerCase(get('rule.value', permission)),
									scope: {
										type: 'user',
										value: get('user.email', permission),
									}
								}
							});
							console.log({createdAcl})
							if (createdAcl.error){
								throw Error(createdAcl.error);
							}
						}
						if (permission.id){
							const updatedPermission = await updatePermission({
								input: {
									id: permission.id, 
									rule: get('rule.value', permission),
								}
							});
							console.log({updatedPermission})
						} else {
							const createdPermission = await createPermission({
								input: {
									viewActive: true,
									permissionCalendarId: values.id, 
									permissionUserId: get('user.id', permission), 
									rule: get('rule.value', permission),
									type: "Calendar"
								}
							});
							console.log({createdPermission})
						}
					} catch(err){
						console.log(err)
					}
				}))
			} else { // Connect Calendar doesn't sync with Google
				const removedPermissions = await Promise.all(values.removePermissions.map(async permission => {
					return await deletePermission({ input: { id: permission.id }})
				} ));
				console.log({removedPermissions});
				const newPermissions = values.newPermissions.map(permission => ({
					...permission,
					rule: { value: get('newPermissionsValue.value', values) }
				}))
				const permissions = values.currentPermissions.concat(newPermissions);
				console.log({permissions})
				await Promise.all(permissions.map(async permission => {
					try {
						if (permission.id){
							const updatedPermission = await updatePermission({
								input: {
									id: permission.id, 
									rule: get('rule.value', permission),
								}
							});
							console.log({updatedPermission})
						} else {
							const createdPermission = await createPermission({
								input: {
									viewActive: true,
									permissionCalendarId: values.id, 
									permissionUserId: get('user.id', permission), 
									rule: get('rule.value', permission),
									type: "Calendar"
								}
							});
							console.log({createdPermission})
						}
					} catch(err){
						console.log(err);
					}
				}))
			}
		} catch (e) {
			console.log(e);
			return e;
		}
	}
};