import { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import get from 'lodash/fp/get';
import moment from 'moment';

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import { useCreateNotification } from "./use-notification";
import { useUpdateCalendar, useListCalendarsByUser } from "./use-calendar";
import { useJwtToken } from '../use-user';
import { useDeleteToken } from './use-token';

export const useGetAuth = (userId, trigger) => {
  const [authorized, setAuthorized] = useState(false);
  const [googleAccount, setGoogleAccount] = useState(null);
  const { listTokensByUserId } = useActions(actions);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await listTokensByUserId({
          tokenUserId: userId,
          filter: { service: { eq: 'GOOGLE_CALENDAR' } },
          limit: 1000,
        });
        const token = get('value.items[0]', data);
        if (token) {
          const metadata = JSON.parse(get('metadata', token));
          setAuthorized(true);
          setGoogleAccount(metadata.googleAccount);
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (userId) fetchData();
  }, [userId, trigger]);
  return [authorized, googleAccount];
};

export const useListGoogleCalendars = (userId, jwtToken) => {
  const [calendars, setCalendars] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    const init = { queryStringParameters: { userId, jwtToken } };
    setLoading(true);
    try {
      const res = await API.get('googleCalendarEndpointsAPI', '/calendars', init);
      // console.log(data)
      if (res.success) setCalendars(res.data);
    } catch (e) {
      console.log(e)
    }
    setLoading(false);
  }
  useEffect(() => { if (jwtToken) fetchData() }, [jwtToken]);
  return [{ calendars, loading }];
};

export const isExpiredGoogleCalendarToken = () => {
  const userId = Auth.user && Auth.user.username;
  const [authorized,] = useGetAuth(userId);
  const [isExpired, setIsExpired] = useState(false);
  const jwtToken = useJwtToken();
  const fetchCalendars = async () => {
    if (authorized) {
      const fetchCalendars = await API.get(
        'googleCalendarEndpointsAPI',
        '/calendars',
        { queryStringParameters: { userId, jwtToken } }
      );
      const status = get('data.response.status', fetchCalendars);
      if (status === 400 || status === 401 || status === 403) {
        /*
         400: Bad Request
         401: Unauthorized
         403: Forbidden
       */
        setIsExpired(true)
      }
    }
  }
  useEffect(() => { fetchCalendars() }, [userId, authorized]);
  return isExpired;
};

export const useDeauthorizeGoogleCalendar = (userId, jwtToken) => {
  const deleteToken = useDeleteToken();
  const updateCalendar = useUpdateCalendar();
  const [{ calendars }] = useListCalendarsByUser();
  return async () => {
    try {
      // Remove Token
      const deletedToken = await deleteToken({ userId, service: 'GOOGLE_CALENDAR' });
      // console.log({ deletedToken })
      // // And then offSync calendars, if any
      const calendarsInSync = calendars.filter(calendar => Boolean(calendar.googleCalendarId));
      // console.log({ calendarsInSync })
      await Promise.all(calendarsInSync.map(async calendar => {
        // Request to delete channel
        const stopChannel = await API.del('googleCalendarEndpointsAPI', '/notifications', {
          body: {
            userId,
            jwtToken,
            googleChannelId: calendar.googleChannelId,
            googleResourceId: calendar.googleResourceId
          }
        });
        // console.log({ stopChannel })
        const updatedCalendar = await updateCalendar({
          id: calendar.id,
          googleCalendarId: null,
          googleChannelId: null,
          googleName: null,
          googleResourceId: null,
          googleSyncToken: null,
        })
        // console.log({ updatedCalendar })
      }));
      return "Google Calendar deauthorized and unsync";
    } catch (err) {
      console.log(err);
    }
  }
};

export const useSyncCalendars = (userId, jwtToken) => {
  const createNotification = useCreateNotification();
  const updateCalendar = useUpdateCalendar();
  const onSync = async ({ googleId, googleName, curConnectCalendar }) => {
    try {
      // Request to create channel
      const createdChannel = await API.put('googleCalendarEndpointsAPI', '/notifications', {
        body: {
          userId,
          jwtToken,
          googleCalendarId: googleId,
        }
      });
      console.log({ createdChannel })
      if (createdChannel.error) {
        // Redux
        createNotification({
          timeStamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
          text: `
            Error on sync "${curConnectCalendar.name}" Connect Calendar with "${googleName}" Google Calendar.
            Please contact support.
          `
        });
        return createdChannel;
      }
      if (createdChannel.success) {
        // Initial sync
        return await API.put('googleCalendarEndpointsAPI', '/initialSync', {
          body: {
            userId,
            jwtToken,
            googleCalendarId: googleId,
            connectCalendarId: curConnectCalendar.id,
            googleName,
            googleResourceId: get('data.resourceId', createdChannel),
            googleChannelId: get('data.id', createdChannel),
          }
        }).then(res => {
          // console.log(res)
          // Redux
          createNotification({
            timeStamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
            text: `
              Sync started between "${curConnectCalendar.name}" Connect Calendar and "${googleName}" Google Calendar.
              ${res.data.totalGoogleEvents} events found on Google Calendar.
              ${res.data.addedEventsOnConnectSuccess} future event(s) added on Connect Calendar.
              ${res.data.addedEventsOnConnectError > 0 ?
                res.data.addedEventsOnConnectError + ' future event(s) not added on Connect Calendar. Please contact support to get more details.' : 
                "" 
              }
            `
          });
          return res;
        })
      };
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  const offSync = async ({ googleChannelId, googleResourceId, googleName, prevConnectCalendar }) => {
    try {
      // Request to delete channel
      const stopChannel = await API.del('googleCalendarEndpointsAPI', '/notifications', {
        body: {
          userId, jwtToken, googleChannelId, googleResourceId
        }
      });
      // console.log({ stopChannel })
      if (stopChannel.error) {
        // Redux
        createNotification({
          timeStamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
          text: `
            Error on stopping sync between "${prevConnectCalendar.name}" Connect Calendar and "${googleName}" Google Calendar.
            Please contact support.
          `
        });
      }
      if (stopChannel.success) {
        const updatedCalendar = await updateCalendar({
          id: prevConnectCalendar.id,
          googleCalendarId: null,
          googleChannelId: null,
          googleName: null,
          googleResourceId: null,
          googleSyncToken: null,
        })
        // console.log({ updatedCalendar })
        // Redux
        createNotification({
          timeStamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
          text: `
            Sync removed between "${prevConnectCalendar.name}" Connect Calendar and "${googleName}" Google Calendar.
          `
        });
      }
      return stopChannel;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  return async (values) => {
    // console.log(values)
    try {
      const result = [];
      for (let googleId in values) {
        const calendar = values[googleId];
        if (calendar.willSync == true) {
          result.push(await onSync(calendar));
        }
        else if (calendar.willSync == false && calendar.googleChannelId && calendar.googleResourceId) {
          result.push(await offSync(calendar));
        }
      }
      return result;
    } catch (e) {
      console.log(e)
    }
  }
};

export const useListAcls = ({ userId, jwtToken, googleCalendarId }) => {
  const [acls, setAcls] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    const init = { queryStringParameters: { userId, jwtToken, googleCalendarId } };
    setLoading(true);
    try {
      const res = await API.get('googleCalendarEndpointsAPI', '/acls', init);
      // console.log(res)
      if (res.success) {
        setAcls(res.data);
      }
    } catch (e) {
      console.log(e)
    }
    setLoading(false);
  }
  useEffect(() => { if (jwtToken && googleCalendarId) fetchData() }, [jwtToken, googleCalendarId]);
  return [{ acls, loading }];
};