import { 
  listSubmissionForms,
  listSubmissionFormsByCreatedAt,
} from "../../src";
import { API, graphqlOperation } from "aws-amplify";

export const useSubmissionForms = async (input, sortDirection, type) => {
  if(!input){
    try{
      const { data } = await API.graphql(graphqlOperation(listSubmissionFormsByCreatedAt, {
        limit: 5000,
        sortDirection,
        type
      }));
      return data;
    }catch(e){
      console.log(e)
    }
  }else{
    const fullName = input.trim();
    const idx = fullName.lastIndexOf(' ');
    const firstName = idx !== -1 ? fullName.substring(0, idx) : fullName;
    const lastName = idx !== -1 ? fullName.substring(idx + 1) : '';
    try{
      const { data } = await API.graphql(graphqlOperation(listSubmissionFormsByCreatedAt, {
        limit: 5000,
        sortDirection,
        filter: {
          firstName: {beginsWith: firstName},
          lastName: {beginsWith: lastName}
        },
        type
      }));
      return data;
    }catch(e){
      console.log(e)
    }
  }
};

export const useUnseenSubmissionForms = async (timestamp, type) => {
  const { data } = await API.graphql(graphqlOperation(listSubmissionForms, {
    filter: {
      createdAt: {
        gt: timestamp
      },
      type: {
        eq: type
      }
    }
  }));
  return data;
};
