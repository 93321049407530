import React, { useState } from "react";
import { Auth } from "aws-amplify";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import AuthorizeGoogleCalendarForm from
  '../../../sidebar-page/components/Topbar/components/UserMenu/components/Settings/components/AuthorizeGoogleCalendarForm';

import { useJwtToken } from '../../../../utils/use-user';
import { GOOGLE_CALENDAR_AUTH_URL } from '../../../../api/googleCalendar';
import { useDeauthorizeGoogleCalendar } from '../../../../utils/crm/use-google-calendar';

const useStyles = makeStyles(theme => ({
  title: {
    '& .MuiTypography-h6': {
      fontSize: '16px',
      fontWeight: 600
    }
  },
  content: {
    fontSize: '14px'
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const TokenDialog = ({ open, setOpenDialog }) => {
  const classes = useStyles();
  const [openForm, setOpenForm] = useState(false);
  const userId = Auth.user.username;
  const jwtToken = useJwtToken();
  const deauthorizeGoogleCalendar = useDeauthorizeGoogleCalendar(userId, jwtToken);
  const handleAuthorize = values => {
    const paramState = JSON.stringify({ userId, jwtToken, googleAccount: values.account });
    window.location = `${GOOGLE_CALENDAR_AUTH_URL}&state=${paramState}&login_hint=${values.account}`;
  };
  const handleDeauthorize = async () => {
    setOpenDialog(false);
    try {
      const data = await deauthorizeGoogleCalendar();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle className={classes.title}>
        Reauthorize Google Calendar Integration
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography color="inherit">
          Please reauthorize Google Calendar to keep the sync betweeen Connect and Google Calendars.
          <br />
          <br />
          If you don't want to authorize the integration with Google Calendar, dismiss this message.
        </Typography>
      </DialogContent>
      <If condition={openForm}>
        <DialogActions style={{ padding: '16px 24px', justifyContent: 'flex-start' }}>
          <AuthorizeGoogleCalendarForm id="google-calendar-account" onSubmit={handleAuthorize} />
        </DialogActions>
      </If>
      <DialogActions style={{ padding: '16px' }}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          width={136}
          onClick={handleDeauthorize}
        >
          Dismiss
        </Button>
        <If condition={!openForm}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            width={136}
            onClick={() => setOpenForm(true)}
          >
            Reauthorize
          </Button>
        </If>
      </DialogActions>
    </Dialog>
  );
};

export default TokenDialog;