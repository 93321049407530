import { 
  listDownloadFileForms,
  listDownloadFileFormsByCreatedAt,
  updateDownloadFileForm
} from "../../src";
import { API, graphqlOperation } from "aws-amplify";

export const useDownloadFileForms = async (input, product, sortDirection) => {
  const args = {
    type: "DownloadFileForm",
    limit: 5000,
    sortDirection,
  };
  if (input || product != "All") {
    args["filter"] = {}
  }
  if (input) {
    args["filter"]["or"] = [
        { 
          searchFirstName: {
            beginsWith: input.toLowerCase()
          }
        },
        {
          searchLastName: {
            beginsWith: input.toLowerCase()
          }
        },
        {
          email: {
            beginsWith: input.toLowerCase()
          }
        }
    ]
  }
  if (product != "All") {
    args["filter"]["productName"] = {
      eq: product
    }
  }
  const { data } = await API.graphql(graphqlOperation(listDownloadFileFormsByCreatedAt, args));
  return data;
};

export const useUnseenDownloadFileForms = async (timestamp) => {
  const { data } = await API.graphql(graphqlOperation(listDownloadFileForms, {
    filter: {
      createdAt: {
        gt: timestamp
      }
    }
  }));
  return data;
};
