import {
  listContactUsFormsByCreatedAt,
  listContactUsForms,
  updateContactUsForm,
  deleteContactUsForm,
} from "../../src";
import { API, graphqlOperation } from 'aws-amplify';


export const useContactUsForms = async (input, sortDirection) => {
  const args = {
    type: "ContactUsForm",
    limit: 5000,
    sortDirection
  };
  if (input) {
    args["filter"] = {};
    args["filter"]["or"] = [
      {
        searchFirstName: {
          beginsWith: input.toLowerCase()
        }
      },
      {
        searchLastName: {
          beginsWith: input.toLowerCase()
        }
      },
      {
        email: {
          beginsWith: input.toLowerCase()
        }
      }
    ];

  }
  const { data } = await API.graphql(graphqlOperation(listContactUsFormsByCreatedAt, args));
  return data;
};

export const useUnseenContactUsForms = async (timestamp) => {
  const { data } = await API.graphql(graphqlOperation(listContactUsForms, {
    filter: {
      createdAt: {
        gt: timestamp
      }
    }
  }));
  return data;
};

export const useDeleteContactUsForm = async (id) => {
  await API.graphql(
    graphqlOperation(deleteContactUsForm, { input: { id: id } })
  );
};
