import React from 'react';
import styled from "styled-components";
import { Field, Form } from "react-final-form";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from "@material-ui/core/FormHelperText";

import { EMAIL_PATTERN } from '../../../../../../../../../utils/constants';
import GoogleSvg from '../../../../../../../../../static/google/btn_google_light_normal_ios.svg';

const GoogleLogo = styled(GoogleSvg)`
	margin-right: 16px;
	width: 40px;
	height: auto;
	background-color: #FFFFFF;
	border-radius: 4px;
`;

const useStyles = makeStyles((theme) => ({
	formEmail: {
		'& .MuiButton-root': {
			marginTop: theme.spacing(1),
		},
	},
	inputEmail: {
		width: '80%',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
			fontWeight: 400,
		},
		'& .MuiInputLabel-outlined': {
			backgroundColor: 'white',
			paddingRight: '8px'
		},
	},
	authorizeBtn: {
		width: 'fit-content',
		marginTop: theme.spacing(2),
		backgroundColor: '#4285F4',
		padding: '4px 16px 4px 4px',
	}
}));

const AuthorizeGoogleCalendarForm = ({ onSubmit, id }) => {
	const classes = useStyles();
	const mustBeEmail = value => !value ? undefined : ((EMAIL_PATTERN.test(value) ? undefined : 'Email not valid'));
	return (
		<Form onSubmit={onSubmit}>
			{({ handleSubmit, modified }) => {
				return (
					<form onSubmit={handleSubmit} id={id} >
						<Field
							name="account"
							variant="outlined"
							validate={mustBeEmail}
							allowNull
							parse={value => value == '' ? null : value}
							render={({ input, meta, ...rest }) => (
								<>
									<TextField
										{...input}
										{...rest}
										className={classes.inputEmail}
										id={input.name}
										label="Google Calendar Account"
										placeholder="Insert account"
									/>
									<Button
										type="submit"
										color="primary"
										disableFocusRipple
										disabled={!modified.account}
										className={classes.authorizeBtn}
										variant="contained"
									>
										<GoogleLogo />
										<Typography style={{ fontWeight: 600 }}>Sign in with Google</Typography>
									</Button>
									<FormHelperText id={`${input.name}-helper-text`}>
										<If condition={meta.error && meta.touched}>
											<Typography variant="caption" color="error">{meta.error}</Typography>
										</If>
									</FormHelperText>
								</>
							)}
						/>
					</form>
				)
			}}
		</Form>
	);
};

export default AuthorizeGoogleCalendarForm;