import React from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import mutators from "final-form-arrays";
import dynamic from "next/dynamic";
const SunEditor = dynamic(() => import("suneditor-react"), {ssr: false});

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from '@material-ui/core/styles';

import { useHandleImageUpload, 	useGetEmailSignature } from '../../../utils/crm/use-gmail';

const useStyles = makeStyles((theme) => ({
  toolbarTop: {
    minHeight: 0
  },
  toolbarBottom: {
    padding: 0
  },
}));

const FormLayout = styled("form")`
  display: flex;
  flex-direction: column;
`;

const Editor = ({ input }) => {
  const cognito_user_email = Auth.user.attributes.email;
  const signature = useGetEmailSignature(cognito_user_email);

  const boldIcon= `<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="22 15 50 75" x="0px" y="0px"><title>Artboard 49</title><path d="M61.81,47.54A16,16,0,0,0,49,22H29V78H55a16,16,0,0,0,6.81-30.46ZM37,30H49a8,8,0,0,1,0,16H37ZM55,70H37V54H55a8,8,0,0,1,0,16Z"/></svg>`
  const italicIcon = `<svg style="height: 20px; width: 20px" viewBox="0 1 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4903 5.45801H17.4903L16.7788 7.32716H14.7788L11.2212 16.6729H13.2212L12.5097 18.5421H6.5097L7.22122 16.6729H9.22122L12.7788 7.32716H10.7788L11.4903 5.45801Z" fill="currentColor" /></svg>`
  const underlineIcon = `<svg style="height: 20px; width: 20px" viewBox="-1 1 24 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 10V4H8V10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10V4H18V10C18 13.3137 15.3137 16 12 16C8.68629 16 6 13.3137 6 10Z" fill="currentColor" /><path d="M7 18C6.44772 18 6 18.4477 6 19C6 19.5523 6.44771 20 7 20H17C17.5523 20 18 19.5523 18 19C18 18.4477 17.5523 18 17 18H7Z" fill="currentColor" /></svg>`
  const linkIcon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" /></svg>`
  const imageIcon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" /></svg>`

  return (
    <SunEditor
      placeholder="Add Signature"
      onChange={(newValue) => {
        input.onChange(newValue)
      }}
      setOptions={{
        buttonList: [
          ['font', 'fontSize'], ['bold', 'underline', 'italic'], ['fontColor'], ['link', 'image', 'align'], ['removeFormat']
        ],
        "showPathLabel" : false,
        "icons": {
          bold: boldIcon,
          italic: italicIcon,
          underline: underlineIcon,
          link: linkIcon,
          image: imageIcon,
        }
      }}
      onImageUploadBefore={(files, info, uploadHandler) => {useHandleImageUpload(files, info, uploadHandler)}}
      setContents={signature}
    />
  )
};

const EmailSignature = ({ onSubmit, initialValues }) => {
  const classes = useStyles();
  return (
    <Form onSubmit={onSubmit} mutators={mutators} initialValues={initialValues}>
      {({ handleSubmit, values, hasValidationErrors }) => (
        <FormLayout onSubmit={handleSubmit}>
          {/* {console.log(values)} */}
          <Toolbar className={classes.toolbarTop} disableGutters>
            <Typography variant="h6" gutterBottom>
              Signature
            </Typography>
          </Toolbar>
          <Box>
            <Field component={Editor} name="signature" />
          </Box>
          <Toolbar className={classes.toolbarBottom}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={hasValidationErrors}
            >
              Save signature
            </Button>
          </Toolbar>
        </FormLayout>
        
      )}
    </Form>
  );
};

export default EmailSignature;