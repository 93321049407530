import awsconfig from '../../src/aws-exports';
import { find } from 'lodash';

// Each amplify env has a different registered app in Panafold's Mailchimp account
const LIST_CLIENT_ID = {
    'testing': '273632104540',
    'staging': '197649310316',
    'develine': '492943785951',
    'production': '161813165337',
    'deva': '911666052678'
};

const mailchimpWebhookAPI = find(awsconfig.aws_cloud_logic_custom, ['name', 'mailchimpWebhookAPI']); // API gateway for mailchimpWebhook lambda function
const mailchimpAuthorizeAPI = find(awsconfig.aws_cloud_logic_custom, ['name', 'mailchimpAuthorizeAPI']); // API gateway for mailchimpAuthorize lambda function
const currentEnv = mailchimpAuthorizeAPI.endpoint && mailchimpAuthorizeAPI.endpoint.split('amazonaws.com/')[1];

const WEBHOOK_API_GATEWAY = `${mailchimpWebhookAPI && mailchimpWebhookAPI.endpoint}/webhook`; 
const REDIRECT_URI = `${mailchimpAuthorizeAPI && mailchimpAuthorizeAPI.endpoint}/auth`; 
const CLIENT_ID = LIST_CLIENT_ID[currentEnv];
const AUTH_URI = 'https://login.mailchimp.com/oauth2/authorize';

export const WEBHOOK_URL = `${WEBHOOK_API_GATEWAY}?appId=${CLIENT_ID}`;
export const MAILCHIMP_AUTH_URL = `${AUTH_URI}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
export const ACCOUNT_NAME = '33Sixty';