import { useState, useEffect } from "react";
import get from 'lodash/fp/get';
import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import { useCreateEventAttendee, useDeleteEventAttendee } from './use-event-attendee';
import { TIME_TABLE } from '../time-table';

export const addOneDay = (date) => {
	// Considering date formatted as "YYYY-MM-DD".
	return moment(addDays(new Date(date + "T00:00"), 1)).format("YYYY-MM-DD");
};

export const substractOneDay = (date) => {
	// Considering date formatted as "YYYY-MM-DD".
	return moment(subDays(new Date(date + "T00:00"), 1)).format("YYYY-MM-DD");
};

export const parseTimeEvent = ({ allDay, startDate, endDate, startTime, endTime }) => {
	const start_date_pretty = moment(startDate).format('LL');
	const end_date_pretty = moment(endDate).format('LL');
	if (allDay && startDate === endDate) {
		return start_date_pretty;
	} else if (allDay && startDate !== endDate) {
		return `${start_date_pretty} - ${end_date_pretty}`;
	} else if (startDate === endDate) {
		if (startTime && endTime) return `${start_date_pretty} at ${startTime} - ${endTime}`;
		else return start_date_pretty;
	} else {
		if (startTime && endTime) return `${start_date_pretty}, ${startTime} - ${end_date_pretty}, ${endTime}`;
		else return start_date_pretty;
	}
};

export const useEvents = (trigger) => {
	const { listEvents } = useActions(actions);
	const [events, setEvents] = useState([]);

	const fetchEvents = async () => {
		try {
			let token = null;
			let result = [];
			while(true){
				const data = await listEvents({ limit: 1000, nextToken: token });
				// console.log(data)
				token = data.value.nextToken;
				result = result.concat(data.value.items);
				if (token == null) break;
			}
			setEvents(result);
		} catch (e) {
			console.log(e);
			setEvents([]);
		}
	};

	useEffect(() => {
		fetchEvents();
	}, [trigger]);
	// console.log(events)
	return [{ events, setEvents }];
};

export const useEventsByCalendars = ({ calendars, activeStart, activeEnd, trigger }) => {
	const { listEvents } = useActions(actions);
	const [events, setEvents] = useState([]);
	const fetchEvents = async () => {
		if (calendars.length === 0) {
			setEvents([]);
			return;
		}
		try {
			const data = await listEvents({
				limit: 1000,
				filter: {
					and: [
						{ startDate: { ge: activeStart }, endDate: { le: activeEnd } },
						{ or: calendars.map(calendar => ({ eventCalendarId: { eq: calendar.id } })) }
					]
				}
			});
			// console.log({data});
			setEvents(data.value.items);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => { fetchEvents() }, [calendars, activeStart, activeEnd, trigger]);
	return [{ events, setEvents, trigger }];
};

export const useEvent = (eventId, trigger) => {
	const { getEvent } = useActions(actions);
	const [event, setEvent] = useState(null);
	const [loading, setLoading] = useState(null);
	const fetchEvent = async () => {
		if (eventId) {
			setLoading(true);
			try {
				const data = await getEvent({ id: eventId });
				// console.log(data)
				setEvent(data.value);
			} catch (e) {
				console.log(e);
				setEvent(null);
			}
			setLoading(false);
		} else {
			setEvent(null);
		}
	};
	useEffect(() => { fetchEvent() }, [eventId, trigger]);
	return [{ event, loading }];
};

export const useCreateEvent = () => {
	const { createEvent, createActivity } = useActions(actions);
	const createEventAttendee = useCreateEventAttendee();
	/*
		- Create Event
		- Then create EventAttendees
	*/
	return async (values) => {
		console.log(values)
		try {		
			const allDay = get('allDay', values);
			const startDate = get('startDate', values);
			const endDate = allDay ? addOneDay(get('endDate', values)) : get('endDate', values);
			const startTime = allDay ? null : get('startTime.twentyFourHour', values);
			const endTime = allDay ? null : get('endTime.twentyFourHour', values);
			const startTimeZoneHour = allDay ? null : get('startTimeZone.hourValue', values);
			const endTimeZoneHour = allDay ? null : get('endTimeZone.hourValue', values);
			const startTimeZoneName = allDay ? null : get('startTimeZone.namedValue', values);
			const endTimeZoneName = allDay ? null : get('endTimeZone.namedValue', values);
			const input = {
				id: uuidv4(),
				allDay,
				startDate,
				endDate,
				startTime,
				endTime,
				startTimeZoneHour,
				endTimeZoneHour,
				startTimeZoneName,
				endTimeZoneName,
				creatorId: get('creatorId', values),
				title: get('title', values),
				location: get('location', values),
				description: get('description', values),
				eventCalendarId: get('calendar.id', values),
				source: "CONNECT",
				googleEventId: get('calendar.googleCalendarId', values) ? uuidv4().split('-').join('') : undefined,
			};	
			// const eventId = get('value.id', createdEvent);
			const contacts = get('contacts', values);
      const companies = get('companies', values);
      const employees = get('employees', values);
			const attendeesContacts = await Promise.all(contacts.map(contact => createEventAttendee({
				typeAttendee: 'CONTACT',
				eventAttendeeEventId: input.id,
				eventAttendeeAttendeeContactId: contact.id
			})));
			// console.log('attendeesContacts', attendeesContacts)
      const attendeesCompanies = await Promise.all(companies.map(company => createEventAttendee({
				typeAttendee: 'COMPANY',
				eventAttendeeEventId: input.id,
				eventAttendeeAttendeeCompanyId: company.id
			})));
			// console.log('attendeesCompanies', attendeesCompanies)
			const attendeesEmployees = await Promise.all(employees.map(employee => createEventAttendee({
				typeAttendee: 'EMPLOYEE',
				eventAttendeeEventId: input.id,
				eventAttendeeAttendeeEmployeeId: employee.id
			})));
			// console.log('attendeesEmployees', attendeesEmployees)
			const cleanArray = (list) => list.map(el => ({
				id: el.id,
				name: el.name || el.full_name
			}));
			const createdEvent = await createEvent({ input });
			// console.log({ createdEvent })
			// Log Activity
			const activity = await createActivity({
				input: {
					activityId: input.id,
					type: 'ACTIVITY',
					creatorId: input.creatorId,
					activityCreatorId: input.creatorId,
					action: "CREATED",
					activityType: "EVENT",
					payload: {
						name: input.title,
						attendeeContacts: cleanArray(contacts),
            attendeeCompanys: cleanArray(companies),
						attendeeEmployees: cleanArray(employees),
						note: input.description,
						date: parseTimeEvent({ 
							allDay: input.allDay, 
							startDate: input.startDate, 
							endDate: input.endDate, 
							startTime: input.startTime, 
							endTime: input.endTime
						})
					}
				}
			});
			// console.log({ activity })
			return createdEvent.action;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
}

export const useUpdateEvent = () => {
	const { updateEvent, getEvent, createActivity } = useActions(actions);
	const createEventAttendee = useCreateEventAttendee();
	const deleteEventAttendee = useDeleteEventAttendee();
	/*
		- Update Event
		- Create EventAttendees, if adding
		- Delete EventAttendees, if removing
	*/
	return async (values) => {
		// console.log(values)
		try {			
			const eventId = get('id', values);
			const previousEvent = await getEvent({ id: get('id', values) });
			// console.log({ previousEvent })
			const allDay = get('allDay', values);
			const startDate = get('startDate', values);
			const endDate = allDay ? addOneDay(get('endDate', values)) : get('endDate', values);
			const startTime = allDay ? null : get('startTime.twentyFourHour', values);
			const endTime = allDay ? null : get('endTime.twentyFourHour', values);
			const startTimeZoneHour = allDay ? null : get('startTimeZone.hourValue', values);
			const endTimeZoneHour = allDay ? null : get('endTimeZone.hourValue', values);
			const startTimeZoneName = allDay ? null : get('startTimeZone.namedValue', values);
			const endTimeZoneName = allDay ? null : get('endTimeZone.namedValue', values);
			const input = {
				id: eventId,
				allDay,
				startDate,
				endDate,
				startTime,
				endTime,
				startTimeZoneHour,
				endTimeZoneHour,
				startTimeZoneName,
				endTimeZoneName,
				title: get('title', values),
				location: get('location', values),
				description: get('description', values),
				eventCalendarId: get('calendar.id', values),
			};
			const previousAttendees = previousEvent.value.attendees.items;
			const currentContacts = get('contacts', values);
			const previousContacts = previousAttendees
				.filter(attendee => attendee.typeAttendee === 'CONTACT')
				.map(({ id, attendeeContact }) => ({ eventAttendeeId: id, ...attendeeContact }));
			const contactsToAdd = differenceBy(currentContacts, previousContacts, 'id');
			const contactsToRemove = differenceBy(previousContacts, currentContacts, 'id');
			const createdAttendeesContacts = await Promise.all(contactsToAdd.map(contact => createEventAttendee({
				typeAttendee: 'CONTACT',
				eventAttendeeEventId: eventId,
				eventAttendeeAttendeeContactId: contact.id
			})));
			const removedAttendeesContacts = await Promise.all(contactsToRemove.map(contact => deleteEventAttendee({ id: contact.eventAttendeeId })));
			// console.log({ 
			// 	previousContacts, 
			// 	currentContacts, 
			// 	contactsToAdd, 
			// 	contactsToRemove, 
			// 	createdAttendeesContacts, 
			// 	removedAttendeesContacts 
			// });
      const currentCompanies = get('companies', values);
			const previousCompanies = previousAttendees
				.filter(attendee => attendee.typeAttendee === 'COMPANY')
				.map(({ id, attendeeCompany }) => ({ eventAttendeeId: id, ...attendeeCompany }));
			const companiesToAdd = differenceBy(currentCompanies, previousCompanies, 'id');
			const companiesToRemove = differenceBy(previousCompanies, currentCompanies, 'id');
			const createdAttendeesCompanies = await Promise.all(companiesToAdd.map(company => createEventAttendee({
				typeAttendee: 'COMPANY',
				eventAttendeeEventId: eventId,
				eventAttendeeAttendeeCompanyId: company.id
			})));
			const removedAttendeesCompanies = await Promise.all(companiesToRemove.map(company => deleteEventAttendee({ id: company.eventAttendeeId })));
			// console.log({ 
			// 	previousCompanies, 
			// 	currentCompanies, 
			// 	companiesToAdd, 
			// 	companiesToRemove, 
			// 	createdAttendeesCompanies, 
			// 	removedAttendeesCompanies
			// });
			const currentEmployees = get('employees', values);
			const previousEmployees = previousAttendees
				.filter(attendee => attendee.typeAttendee === 'EMPLOYEE')
				.map(({ id, attendeeEmployee }) => ({ eventAttendeeId: id, ...attendeeEmployee }));
			const employeesToAdd = differenceBy(currentEmployees, previousEmployees, 'id');
			const employeesToRemove = differenceBy(previousEmployees, currentEmployees, 'id');
			const createdAttendeesEmployees = await Promise.all(employeesToAdd.map(employee => createEventAttendee({
				typeAttendee: 'EMPLOYEE',
				eventAttendeeEventId: eventId,
				eventAttendeeAttendeeEmployeeId: employee.id
			})));
			const removedAttendeesEmployees = await Promise.all(employeesToRemove.map(employee => deleteEventAttendee({ id: employee.eventAttendeeId })));
			// console.log({ 
			// 	previousEmployees, 
			// 	currentEmployees, 
			// 	employeesToAdd, 
			// 	employeesToRemove, 
			// 	createdAttendeesEmployees, 
			// 	removedAttendeesEmployees 
			// });
			const updatedAttendees = await getEvent({ id: get('id', values) })
				.then(res => res.value.attendees.items).catch(err => console.log(err));
			console.log({ updatedAttendees });
			const contactAttendees = updatedAttendees
				.filter(({ typeAttendee }) => typeAttendee === "CONTACT")
				.map(({ attendeeContact}) => attendeeContact);
			const companyAttendees = updatedAttendees
				.filter(({ typeAttendee }) => typeAttendee === "COMPANY")
				.map(({ attendeeCompany}) => attendeeCompany);
			const employeeAttendees = updatedAttendees
				.filter(({ typeAttendee }) => typeAttendee === "EMPLOYEE")
				.map(({ attendeeEmployee}) => attendeeEmployee);
			const cleanArray = (list) => list.map(el => ({
				id: el.id,
				name: el.name || el.full_name
			}));
			const updatedEvent = await updateEvent({ input });
			// console.log({ updatedEvent })
			// Log Activity
			const user = await Auth.currentAuthenticatedUser();
			// console.log(user)
			const activity = await createActivity({
				input: {
					activityId: eventId,
					type: 'ACTIVITY',
					creatorId: user.username,
					activityCreatorId: user.username,
					action: "UPDATED",
					activityType: "EVENT",
					payload: {
						name: input.title,
						attendeeContacts: cleanArray(contactAttendees),
            attendeeCompanys: cleanArray(companyAttendees),
						attendeeEmployees: cleanArray(employeeAttendees),
						note: input.description,
						date: parseTimeEvent({ 
							allDay: input.allDay, 
							startDate: input.startDate, 
							endDate: input.endDate, 
							startTime: input.startTime, 
							endTime: input.endTime
						})
					}
				}
			});
 			// console.log({ activity })
			return updatedEvent.action;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
}

export const useDeleteEvent = () => {
	const { deleteEvent, createActivity, listEventAttendeesByEvent } = useActions(actions);
	const deleteEventAttendee = useDeleteEventAttendee();
	/*
		- Delete EventAttendees associated
		- Then delete Event
	*/
	return async (event) => {
		try {
			// console.log(event)
			const attendees = await listEventAttendeesByEvent({ eventAttendeeEventId: event.id })
				.then(res => res.value.items);
			const deletedAttendees = await Promise.all(attendees.map(item => deleteEventAttendee({ id: item.id })));
			// console.log('deletedAttendees', deletedAttendees)
			const deletedEvent = await deleteEvent({ input: { id: event.id } });
			// console.log('deletedEvent', deletedEvent)
			const eventId = get('value.id', deletedEvent);
			const creatorId = get('value.creatorId', deletedEvent);
			const title = get('value.title', deletedEvent);
			const description = get('value.description', deletedEvent);
			const allDay = get('value.allDay', deletedEvent);
			const getTime = time => TIME_TABLE.find(option => option.twentyFourHour === time);
			const startDate = get('value.startDate', deletedEvent);
			const endDate = get('value.endDate', deletedEvent);
			const startTime = getTime(get('value.startTime', deletedEvent));
			const endTime = getTime(get('value.endTime', deletedEvent));
			// Log Activity
			const user = await Auth.currentAuthenticatedUser();
			// console.log(user)
			const activity = await createActivity({
				input: {
					activityId: eventId,
					type: 'ACTIVITY',
					creatorId: user.username,
					activityCreatorId: user.username,
					action: "DELETED",
					activityType: "EVENT",
					payload: {
						name: title,
						note: description,
						date: parseTimeEvent({ 
							allDay, 
							startDate, 
							endDate, 
							startTime: get('twelveHour', startTime), 
							endTime: get('twelveHour', endTime)
						})
					}
				}
			});
			// console.log({ activity })
			return deletedEvent.action;
		} catch (e) {
			console.log(e);
			return e;
		}
	}
}