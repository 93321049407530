import awsconfig from '../../src/aws-exports';
import { find } from 'lodash';

// API gateway for googleCalendarAuthorize lambda function
const googleCalendarAuthorizeAPI = find(
    awsconfig.aws_cloud_logic_custom, 
    ['name', 'googleCalendarAuthorizeAPI']
);
// Each amplify env has a different Google client ID
const LIST_CLIENT_ID = {
    'production': '1020413683959-td7dp6u6gmpg6p28s6i4lcol1080v1mk.apps.googleusercontent.com',
    'staging': '318793229421-6irfgeaet4jl80dr1bumel0gb2g5fknj.apps.googleusercontent.com',
    'testing': '',
    'develine': '1061632345254-fon44ujfe45j4mrnaq9dchubful82uk3.apps.googleusercontent.com',
    'devalex': '406635433007-l9vc20epip46ua4gvo5s086t7vfboe8t.apps.googleusercontent.com',
    'deva': '904781367515-2balvs1o3j39m4budgk6hvb3vlujsbvm.apps.googleusercontent.com'
};
const currentEnv = googleCalendarAuthorizeAPI.endpoint && googleCalendarAuthorizeAPI.endpoint.split('amazonaws.com/')[1];
const CLIENT_ID = LIST_CLIENT_ID[currentEnv];
const SCOPES = 'https://www.googleapis.com/auth/calendar';
const REDIRECT_URI = currentEnv === 'production' ? 
  'https://api.connect.panafold.com/googleCalendarAuthorize/auth' : 
  `${googleCalendarAuthorizeAPI && googleCalendarAuthorizeAPI.endpoint}/auth`;
const AUTH_URI = 'https://accounts.google.com/o/oauth2/v2/auth';
export const GOOGLE_CALENDAR_AUTH_URL = `${AUTH_URI}?access_type=offline&scope=${SCOPES}&response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&prompt=consent`;