import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';

export const useCreateEventAttendee = () => {
	const { createEventAttendee } = useActions(actions);
	return async values => {
		return await createEventAttendee({ input: values });
	}
};

export const useDeleteEventAttendee = () => {
	const { deleteEventAttendee } = useActions(actions);
	return async values => {
		return await deleteEventAttendee({ input: values });
	}
};