import { createUserSession, getUserSession, updateUserSession} from '../../src';
import { API, graphqlOperation } from 'aws-amplify';

export const useUpdateUserSession = async (email, timestamp, page) => {
  const args = {
    id: email
  };
  if (page == 'download-form') {
    args["lastDownloadFormSeen"] = timestamp;
    args["lastDownloadNotificationSeen"] = timestamp;
  } else if (page == 'contact-form') { 
    args["lastContactFormSeen"] = timestamp;
    args["lastContactNotificationSeen"] = timestamp;
  }  else if (page == 'trade-program-form') {
    args["lastTradeProgramFormSeen"] = timestamp;
    args["lastTradeProgramNotificationSeen"] = timestamp;
  } else if (page == 'newsletter-form') {
    args["lastNewsletterFormSeen"] = timestamp;
    args["lastNewsletterNotificationSeen"] = timestamp;
  } else if (page == 'add-contact-form') {
    args["lastAddContactFormSeen"] = timestamp;
    args["lastAddContactNotificationSeen"] = timestamp;
  }
  
  try {
    const { data } = await API.graphql(graphqlOperation(updateUserSession, {
      input: args
    }));
    return data;
  } catch (err) {
    console.log(err)
  }
}

export const useUserSession = async (email) => {
  const { data } = await API.graphql(graphqlOperation(getUserSession, {
    id: email
  }));

  if (data.getUserSession) {
    return data.getUserSession;
  } else {
    const timestamp = new Date().toISOString();
    const args = {
      id: email,
    };
    const { data } = await API.graphql(graphqlOperation(createUserSession, {
      input: {
        id: email,
      }
    }));
    return data.createUserSession;
  }
};

export const useUpdateNotification = async (email, timestamp, notification) => {
  const args = {
    id: email
  };
  if (notification == 'download-form') {
    args["lastDownloadNotificationSeen"] = timestamp;
  } else if (notification == 'contact-form') { 
    args["lastContactNotificationSeen"] = timestamp;
  } else if (notification == 'newsletter-form') { 
    args["lastNewsletterNotificationSeen"] = timestamp;
  } else if (notification == 'add-contact-form') { 
    args["lastAddContactNotificationSeen"] = timestamp;
  } 
  
  try {
    const { data } = await API.graphql(graphqlOperation(updateUserSession, {
      input: args
    }));
    return data;
  } catch (err) {
    console.log(err)
  }

}
