import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import compact from "lodash/fp/compact";
import get from "lodash/fp/get";
import _join from 'lodash/join';
import differenceBy from "lodash/differenceBy";

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import * as crmActions from '../../redux/actions/crm';
import { listGmailConfigs } from "../../src";
import { useCreateAsset, useUpdateAsset } from './use-asset';
import { Auth } from 'aws-amplify';

export const useGetEmailDraft = (recipientId) => {
  const draft = useSelector(state =>
    state.crm.emailDrafts
      ? state.crm.emailDrafts[recipientId]
      : null
  );
  return draft;
};

export const useDeleteEmailDraft = () => {
  const deleteEmailDraft = useActions(crmActions.deleteEmailDraft);
  return value => {
    return deleteEmailDraft(value);
  };
};

// Used for both creating and updating drafts
export const useUpdateEmailDraft = () => {
  const updateEmailDraft = useActions(crmActions.updateEmailDraft);
  return value => {
    return updateEmailDraft(value);
  };
};

export const useGetEmailSignature = (userEmail) => {
  const fetch = useActions(crmActions.getEmailSignature)(userEmail); // fetch signature
  const signature = useSelector(state => state.crm.emailSignature ? state.crm.emailSignature : "");
  return signature;
};

export const useUpdateSignature = () => {
  const updateSignature = useActions(crmActions.updateEmailSignature);
  return async values => {
    return updateSignature(values);
  }
};

export const useHandleImageUpload = (files, info, uploadHandler) => {
  (async () => {
    const createAsset = useCreateAsset();
    try {
      const createdAssets = await Promise.all(
        files.map((file, index) => createAsset({
          file,
          s3FolderName: 'signature_image',
          values: {
            name: file.name,
            index
          }
        }))
      );
      const imageUrl = get('createAsset.url', createdAssets[0]) || "";
      const imageName = get('createAsset.name', createdAssets[0]) || "";
      const response = {
        result: [
          {
            url: imageUrl,
            name: imageName
          }
        ]
      };
      uploadHandler(response);
    } catch (e) {
      console.log(e);
    }
  })();
  uploadHandler();
};

export const useSaveDefaultEmail = () => {
  const { createGmailConfig, updateGmailConfig, getGmailConfigByCognitoEmail } = useActions(actions);
  return async values => {
    // console.log(values)
    const { cognito_user_email, default_email } = values;
    try {
      // get gmail config by cognito user email
      const gmailConfig = await getGmailConfigByCognitoEmail({ cognito_user_email });
      // console.log('gmailConfig', gmailConfig)
      if (gmailConfig.value.items.length) {
        const { id } = gmailConfig.value.items[0];
        // found: update
        const updated = await updateGmailConfig({
          input: {
            id,
            cognito_user_email,
            gmail_address: default_email
          }
        });
        // console.log('updated', updated)
        return updated;
      } else {
        // not found: create
        const created = await createGmailConfig({
          input: {
            cognito_user_email,
            gmail_address: default_email
          }
        });
        // console.log('created', created)
        return created;
      }
    } catch (e) {
      console.log(e);
      return e;
    }
  }
};

export const useDeleteGmailConfig = () => {
  const { deleteGmailConfig, getGmailConfigByCognitoEmail } = useActions(actions);
  return async values => {
    // console.log(values)
    const { cognito_user_email } = values;
    try {
      // get gmail config by cognito user email
      const gmailConfig = await getGmailConfigByCognitoEmail({ cognito_user_email });
      // console.log('gmailConfig', gmailConfig)
      const { id } = gmailConfig.value.items[0];
      // found: delete
      const deleted = await deleteGmailConfig({
        input: {
          id,
        }
      });
      console.log('deleted', deleted)
      return deleted;
    } catch (e) {
      console.log(e);
      return e;
    }
  }
};


// export const useGetEmailSignature = (userEmail, trigger) => {
//   const [signature, setSignature] = useState("");
//   const { getGmailConfigByCognitoEmail } = useActions(actions);
//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const data = await getGmailConfigByCognitoEmail({ cognito_user_email: userEmail });
//         const config = get('value.items[0]', data);
//         const signature = get('signature', config) || "";
//         setSignature(signature);
//       } catch (e) {
//         console.log(e)
//       }
//     }
//     fetchData();
//   }, [trigger]);
//   return signature;
// };

export const useGetGmailAddressByUser = (userEmail, trigger) => {
  const [address, setAddress] = useState(null);
  const { getGmailConfigByCognitoEmail } = useActions(actions);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getGmailConfigByCognitoEmail({ cognito_user_email: userEmail });
        if (data.value.items[0]) setAddress(data.value.items[0].gmail_address);
        else setAddress(null);
      } catch (e) {
        console.log(e)
      }
    }
    fetchData();
  }, [trigger]);
  return address;
};

export const useSendEmail = () => {
  const createAsset = useCreateAsset();
  const updateDB = useUpdateDB();

  return async values => {
    // console.log(values)
    try {
      const attachments = values.attachments.filter(file => file instanceof Blob);
      // Upload attachments to S3 and create new Asset entity with URL returned
      const createdAssets = await Promise.all(
        attachments.map((file, index) => createAsset({
          file,
          s3FolderName: 'message_attachments',
          values: {
            name: file.name,
            index
          }
        }))
      );
      // console.log('createdAssets', createdAssets)

      // Encode message
      const encodedMessage = createBody(values, createdAssets);
      // console.log('encodedMessage', encodedMessage)

      const init = {
        body: {
          cognito_user_email: values.cognito_user_email,
          gmail_address: values.from,
          encodedMessage
        }
      };
      const sentEmail = await API.post('gmailEndpointsAPI', '/sendEmail', init);
      // console.log('sentEmail', sentEmail)

      if (sentEmail.success) {
        // Update DB
        await updateDB(values, createdAssets);
      }

      return sentEmail;

    } catch (err) {
      console.log(err)
    }
  }
};

const useUpdateDB = () => {
  const {
    searchContactsCustom,
    searchCompanysCustom,
    listEmployees,
    createMessage,
    createMessageContact,
    createMessageCompany,
    createMessageEmployee,
    createActivity
  } = useActions(actions);

  const updateAsset = useUpdateAsset();

  return async (values, assets) => {
    try {
      // Get employee data
      const employee = await listEmployees({ filter: { email: { eq: values.cognito_user_email } } });
      const employeeId = employee.value.items[0].id;
      // console.log('employeeId', employeeId)

      const to = compact(values.to);
      const cc = compact(values.cc);
      const bcc = compact(values.bcc);

      let toContacts = [];
      let ccContacts = [];
      let bccContacts = [];

      let toCompanys = [];
      let ccCompanys = [];
      let bccCompanys = [];

      let toEmployees = [];
      let ccEmployees = [];
      let bccEmployees = [];

      let toOthers = null;
      let ccOthers = null;
      let bccOthers = null;

      if (to.length) {
        const fetchContacts = await searchContactsCustom({
          filter: { or: to.map(email => ({ primary_email: { eq: email } })) },
          limit: 1000
        });
        toContacts = fetchContacts.value.searchContacts.items.map(contact => ({
          id: contact.id,
          email: contact.primary_email,
          name: contact.full_name,
          typeRecipient: 'TO'
        }));

        const fetchCompanys = await searchCompanysCustom({
          filter: { or: to.map(email => ({ email: { eq: email } })) },
          limit: 1000
        });
        toCompanys = fetchCompanys.value.searchCompanys.items.map(company => ({
          id: company.id,
          email: company.email,
          name: company.name,
          typeRecipient: 'TO'
        }));

        const employees = await listEmployees({
          filter: { or: to.map(email => ({ email: { eq: email } })) },
          limit: 1000
        });
        toEmployees = employees.value.items.map(employee => ({
          id: employee.id,
          email: employee.email,
          name: employee.name,
          typeRecipient: 'TO'
        }));

        const others = differenceBy(
          to.map(email => ({ email })),
          [...toContacts, ...toCompanys, ...toEmployees],
          'email'
        );
        toOthers = others.length ? others.map(({ email }) => email).join(',') : null;
      }
      // console.log('toContacts', toContacts)
      // console.log('toCompanys', toCompanys)
      // console.log('toEmployees', toEmployees)
      // console.log('toOthers', toOthers)

      if (cc.length) {
        const fetchContacts = await searchContactsCustom({
          filter: { or: cc.map(email => ({ primary_email: { eq: email } })) },
          limit: 1000
        });
        ccContacts = fetchContacts.value.searchContacts.items.map(contact => ({
          id: contact.id,
          email: contact.primary_email,
          name: contact.full_name,
          typeRecipient: 'CC'
        }));

        const fetchCompanys = await searchCompanysCustom({
          filter: { or: cc.map(email => ({ email: { eq: email } })) },
          limit: 1000
        });
        ccCompanys = fetchCompanys.value.searchCompanys.items.map(company => ({
          id: company.id,
          email: company.email,
          name: company.name,
          typeRecipient: 'CC'
        }));

        const employees = await listEmployees({
          filter: { or: cc.map(email => ({ email: { eq: email } })) },
          limit: 1000
        });
        ccEmployees = employees.value.items.map(employee => ({
          id: employee.id,
          email: employee.email,
          name: employee.name,
          typeRecipient: 'CC'
        }));

        const others = differenceBy(
          cc.map(email => ({ email })),
          [...ccContacts, ...ccCompanys, ...ccEmployees],
          'email'
        );
        ccOthers = others.length ? others.map(({ email }) => email).join(',') : null;
      }
      // console.log('ccContacts', ccContacts)
      // console.log('ccCompanys', ccCompanys)
      // console.log('ccEmployees', ccEmployees)
      // console.log('ccOthers', ccOthers)

      if (bcc.length) {
        const fetchContacts = await searchContactsCustom({
          filter: { or: bcc.map(email => ({ primary_email: { eq: email } })) },
          limit: 1000
        });
        bccContacts = fetchContacts.value.searchContacts.items.map(contact => ({
          id: contact.id,
          email: contact.primary_email,
          name: contact.full_name,
          typeRecipient: 'BCC'
        }));

        const fetchCompanys = await searchCompanysCustom({
          filter: { or: bcc.map(email => ({ email: { eq: email } })) },
          limit: 1000
        });
        bccCompanys = fetchCompanys.value.searchCompanys.items.map(company => ({
          id: company.id,
          email: company.email,
          name: company.name,
          typeRecipient: 'BCC'
        }));

        const employees = await listEmployees({
          filter: { or: bcc.map(email => ({ email: { eq: email } })) },
          limit: 1000
        });
        bccEmployees = employees.value.items.map(employee => ({
          id: employee.id,
          email: employee.email,
          name: employee.name,
          typeRecipient: 'BCC'
        }));

        const others = differenceBy(
          bcc.map(email => ({ email })),
          [...bccContacts, ...bccCompanys, ...bccEmployees],
          'email'
        );
        bccOthers = others.length ? others.map(({ email }) => email).join(',') : null;
      }
      // console.log('bccContacts', bccContacts)
      // console.log('bccCompanys', bccCompanys)
      // console.log('bccEmployees', bccEmployees)
      // console.log('bccOthers', bccOthers)

      const allContacts = [].concat(toContacts, ccContacts, bccContacts);
      // console.log('allContacts', allContacts)

      const allCompanys = [].concat(toCompanys, ccCompanys, bccCompanys);
      // console.log('allCompanys', allCompanys)

      const allEmployees = [].concat(toEmployees, ccEmployees, bccEmployees);
      // console.log('allEmployees', allEmployees)

      // Create Message
      const createdMessage = await createMessage({
        input: {
          messageFromId: employeeId,
          toOthers,
          ccOthers,
          bccOthers,
          subject: values.subject,
          body: values.text_html,
        }
      });
      // console.log('createdMessage', createdMessage)
      const messageId = createdMessage.value.id;
      // console.log('messageId', messageId)

      // console.log('assets', assets)

      // Update assets with message ID
      const updatedAssets = await Promise.all(assets.map(asset => {
        return updateAsset({
          id: asset.createAsset.id,
          assetMessageId: messageId
        });
      }))
      // console.log('updatedAssets', updatedAssets)

      // Connect Message to Contacts
      const createdMessageContacts = await Promise.all(allContacts.map(contact => {
        return createMessageContact({
          input: {
            messageContactContactId: contact.id,
            messageContactMessageId: messageId,
            typeRecipient: contact.typeRecipient
          }
        });
      }))
      // console.log('createdMessageContacts', createdMessageContacts)

      // Connect Message to Companys
      const createdMessageCompanys = await Promise.all(allCompanys.map(company => {
        return createMessageCompany({
          input: {
            messageCompanyCompanyId: company.id,
            messageCompanyMessageId: messageId,
            typeRecipient: company.typeRecipient
          }
        });
      }))
      // console.log('createdMessageCompanys', createdMessageCompanys)

      // Connect Message to Employees
      const createdMessageEmployees = await Promise.all(allEmployees.map(employee => {
        return createMessageEmployee({
          input: {
            messageEmployeeEmployeeId: employee.id,
            messageEmployeeMessageId: messageId,
            typeRecipient: employee.typeRecipient
          }
        });
      }))
      // console.log('createdMessageEmployees', createdMessageEmployees)

      if (messageId) {

        const cleanArray = (list) => {
          return list.map(el => {
            delete el.email;
            delete el.typeRecipient;
            return el
          });
        };

        const allOthers = _join(compact([].concat(toOthers, ccOthers, bccOthers)), ',') || null;

        try {
          const user = await Auth.currentAuthenticatedUser();
          await createActivity({
            input: {
              activityId: messageId, // messsageId
              type: 'ACTIVITY',
              creatorId: user.username,
              activityCreatorId: user.username,
              action: "SENT",
              activityType: "EMAIL",
              payload: {
                recipientContacts: cleanArray(allContacts),
                recipientCompanys: cleanArray(allCompanys),
                recipientOthers: allOthers,
                subject: values.subject,
              }
            }
          });
        } catch (e) {
          console.log(e);
        }
      }

      return createdMessage;

    } catch (e) {
      console.log(e);
    }
  }
}

/* Helper */
function createBody(values, assets) {
  let { to, from, cc, bcc, subject, text_html } = values;

  if (assets.length) {
    let attachments = "<br><br><p><strong>Attachments</strong></p>";
    assets.map(asset => {
      const url = asset.createAsset.url;
      const name = asset.createAsset.name;
      attachments += `<a href=${url} target="_blank">${name}</a><br><br>`;
    });
    text_html = text_html.concat(attachments);
  }

  text_html = `
    <!doctype html>
    <html>
    <head>
      <style>
        p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
          margin: 0 !important;
          padding: 0 !important;
        }
      </style>
    </head>
    <body>
      ${text_html}
    </body>
    </html>
  `;

  function encodeEmails(emails) {
    let result = "";
    for (let i = 0; i < emails.length; i++) {
      if (i === emails.length - 1) {
        result += "<" + emails[i] + ">"
      } else {
        result += "<" + emails[i] + ">,"
      }
    }
    return result;
  }

  function encodeSubject(subject) {
    return '=?utf-8?B?' + Buffer.from(subject).toString('base64') + '?=';
  }

  const nl = "\n";
  const boundary = "__connect_app__";

  let mimeBody = [
    "MIME-Version: 1.0",
    "To: " + encodeEmails(compact(to)),
    "Cc: " + encodeEmails(compact(cc)),
    "Bcc: " + encodeEmails(compact(bcc)),
    "From: " + encodeEmails([from]),
    "Subject: " + encodeSubject(subject),
    "Content-Type: multipart/alternative; boundary=" + boundary + nl,
    // "--" + boundary,
    // "Content-Type: text/plain; charset=UTF-8",
    // "Content-Transfer-Encoding: base64" + nl,
    // Buffer.from(text).toString('base64') + nl,
    "--" + boundary,
    "Content-Type: text/html; charset=UTF-8",
    "Content-Transfer-Encoding: base64" + nl,
    Buffer.from(text_html).toString('base64') + nl,
  ];

  mimeBody.push("--" + boundary + "--");
  // console.log('mimeBody', mimeBody)

  return Buffer.from(mimeBody.join(nl))
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}